import { AssetServiceImpl } from '~/services/assets.service'
import { GroupsServiceImpl } from '~/services/groups.service'
import { ZonesServiceImpl } from '~/services/zones.service'
import { SourceServiceImpl } from '~/services/sources.service'
import { ReportsServiceImpl } from '~/services/reports.service'
import { IncidentTypesServiceImpl } from '~/services/incidentTypes.service'
import { IncidentsServiceImpl } from '~/services/incidents.service'
import { AppIconsServiceImpl } from '~/services/icons.service'
import { NotificationsServiceImpl } from '~/services/notifications.service'
import { RecommendationsServiceImpl } from '~/services/recommendations.service'
import { SubCategoriesServiceImpl } from '~/services/subcategories.service'
import { RiskLevelsServiceImpl } from '~/services/risk-levels.service'
import { CategoriesServiceImpl } from '~/services/categories.service'
import { AccountsServiceImpl } from '~/services/accounts.service'
import { ContactsServiceImpl } from '~/services/contacts.service'
import { SafeZonesServiceImpl } from '~/services/safe-zones.service'
import { FollowUpServiceImpl } from '~/services/followUp.service'
import { WeatherServiceImpl } from '~/services/weather.service';

export default defineNuxtPlugin((nuxtApp) => {
  const supabase = useSupabaseClient()

  nuxtApp.provide('accounts', new AccountsServiceImpl(supabase))
  nuxtApp.provide('categories', new CategoriesServiceImpl(supabase))
  nuxtApp.provide('riskLevels', new RiskLevelsServiceImpl(supabase))
  nuxtApp.provide('subCategories', new SubCategoriesServiceImpl(supabase))
  nuxtApp.provide('recommendations', new RecommendationsServiceImpl(supabase))
  nuxtApp.provide('appIcons', new AppIconsServiceImpl(supabase))
  nuxtApp.provide('notifications', new NotificationsServiceImpl(supabase))
  nuxtApp.provide('incidents', new IncidentsServiceImpl(supabase))
  nuxtApp.provide('incidentTypes', new IncidentTypesServiceImpl(supabase))
  nuxtApp.provide('groups', new GroupsServiceImpl(supabase))
  nuxtApp.provide('assets', new AssetServiceImpl(supabase))
  nuxtApp.provide('zones', new ZonesServiceImpl(supabase))
  nuxtApp.provide('infoSources', new SourceServiceImpl(supabase))
  nuxtApp.provide('reports', new ReportsServiceImpl(supabase))
  nuxtApp.provide('contacts', new ContactsServiceImpl(supabase))
  nuxtApp.provide('safeZones', new SafeZonesServiceImpl(supabase))
  nuxtApp.provide('followUp', new FollowUpServiceImpl(supabase))
  nuxtApp.provide('weather', new WeatherServiceImpl())
})
