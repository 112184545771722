export async function uploadFileAsync(bucket: string, path: string, file: File) {
  const supabase = useSupabaseClient()
  // Step 1: Check if bucket exists for org
  const {data: orgBucket, error: bucketListError} = await supabase.storage.getBucket(bucket);

  if (bucketListError) {
    console.warn('Bucket was not available. Creating...');
    await supabase.storage.createBucket(bucket, {
      public: true,
      // TODO: set app file size limit?
    });
  }

  return await supabase.storage.from(bucket).upload(path, file, {
    cacheControl: '3600',
    upsert: false
  });
}
