import type {EmergencyContact} from '~/types';
import type {PostgrestError} from "@supabase/postgrest-js";
import {SupabaseClient} from "@supabase/supabase-js";

export declare abstract class IContactsService {
  abstract getAll(accountId: string): Promise<EmergencyContact[]>
  abstract get(contactId: string): Promise<EmergencyContact | null>
  abstract update(
    contactId: string,
    data: any
  ): Promise<EmergencyContact>
  delete(contactId: string): Promise<void>
  abstract create(model: any, accountId?: string): Promise<EmergencyContact>
}

export class ContactsServiceImpl implements IContactsService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }
    async delete(contactId: string) {
        const { error } = await this.supabase
            .from('emergencyContact')
            .delete()
            .eq('id', contactId)
        if (error) throw error
    }

    async get(contactId: string): Promise<EmergencyContact | null> {
        const response = await this.supabase
            .from('emergencyContact')
            .select('*')
            .eq('id', contactId)
            .limit(1)
            .single()
        return response.data as EmergencyContact | null
    }

    async getAll(accountId: string): Promise<EmergencyContact[]> {
        const { data, error } = await this.supabase
            .from('emergencyContact')
            .select('*')
            .eq('accountId', accountId)

        if (error) throw error

        return data as unknown as EmergencyContact[]
    }

    async update(contactId: string, data: any): Promise<EmergencyContact> {
        let updateDTO: any = {}
        Object.keys(data).forEach((key) => {
            if (data[key] !== undefined) updateDTO[key] = data[key]
        })

        const {
            data: updateResponse,
            error: updateModelError,
        }: any & PostgrestError = await this.supabase
            .from('emergencyContact')
            .update(updateDTO as never)
            .eq('id', contactId)
            .select('*')

        if (updateModelError) {
            throw updateModelError
        }

        return updateResponse[0]
    }

    async create(model: any, accountId?: string): Promise<EmergencyContact> {
        let updateDTO: any = {}
        Object.keys(model).forEach((key) => {
            if (model[key] !== undefined) updateDTO[key] = model[key]
        })

        if(accountId) {
          updateDTO = {
            ...updateDTO,
            accountId: accountId!
          }
        }

        const {
            data: insertResponse,
            error: insertModelError,
        }: any & PostgrestError = await this.supabase
            .from('emergencyContact')
            .insert([updateDTO as never])
            .select()

        if (insertModelError) throw insertModelError

        return insertResponse[0]
    }
}
