import { useStorage } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Account } from "~/types";

interface AccessTokenPayload {
  id: number
  scopes: string[]
  sessionId: number
  role?: string
  iat: number
  exp: number
  sub: string
}

export const useUserSession = defineStore('userSession', () => {
  const user = useStorage('currentUser', {} as Account, undefined)
  const loading = ref(true)
  const isAdmin = computed(() => user.value.role === 'ADMIN')
  const isAuthorized = computed(() => user.value.role !== 'GUEST')

  const role = computed(() => user.value.role);

  const userId = computed(() => {
    return user.value?.id
  })

  function setUser(newUser: any) {
    user.value = newUser
  }

  function setLoading(isLoading: boolean) {
    loading.value = isLoading
  }

  function clearState() {
    localStorage.removeItem('currentUser')
    user.value = undefined
  }

  return {
    user,
    isAdmin,
    isAuthorized,
    clearState,
    setLoading,
    setUser,
    userId,
    role
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
