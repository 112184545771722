import { canActivate } from '~/composables/canActivate'
import type { RouteLocationNormalized } from 'vue-router'
import posthog from 'posthog-js'
import { useUserSession } from "~/stores/userSession";

export default defineNuxtRouteMiddleware((to, from) => {
  const { withoutPadding } = useCollapse()
  withoutPadding.value = false
  if (Boolean(to.meta?.bypassAuth) && !Boolean(to.meta?.allowedRoles)) return

  // Check if route request is to delete account
  if (from.path.includes('/auth/delete-account')) {
    return console.log('To delete account')
  }

  // Otherwise we run route through auth
  return authMiddleware(to, from)
})

function authMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) {
  const user = useSupabaseUser()
  if (!user.value) {
    useSupabaseClient().auth.signOut();
    useUserSession().clearState();
    return navigateTo({
      path: '/auth/login',
      query: {
        redirect: from.path,
      },
    })
  }

  const { $logger } = useNuxtApp()
  const logger: typeof posthog = $logger as typeof posthog

  logger.identify(user.value.id, {
    email: user.value.email,
    name: `${user.value.user_metadata.firstName} ${user.value.user_metadata.lastName}`,
  })

  // If not allowed, redirect to unauthorized;
  if (!canActivate(to)) {
    return navigateTo({
      path: '/unauthorized',
      query: {
        redirect: from.path,
      },
    })
  }
}
