import type { Account, RegisterUserDTO } from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class IAccountsService {
  abstract getAll(includeInactive?: boolean): Promise<Account[]>
  abstract get(): Promise<Account | null>
  abstract update(userId: string, data: any): Promise<Account>

  abstract deactivate(userId: string): Promise<void>
  abstract registerUser(payload: RegisterUserDTO): Promise<Account>
  abstract deleteAccount(context: string): Promise<void>
}

export class AccountsServiceImpl implements IAccountsService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }
  async getAll(includeInactive?: boolean): Promise<Account[]> {
    if (includeInactive) return this.getAllAccounts()

    return this.getActiveAccounts()
  }

  private async getAllAccounts(): Promise<Account[]> {
    const { data, error } = await this.supabase.from('accounts').select('*')

    if (error) throw error

    return data as unknown as Account[]
  }

  private async getActiveAccounts(): Promise<Account[]> {
    const { data, error } = await this.supabase
      .from('accounts')
      .select('*')
      .eq('isActive', true)

    if (error) throw error

    return data as unknown as Account[]
  }

  async get(): Promise<Account | null> {
    const response = await this.supabase
        .rpc('get_account')
        .single()
    return response.data as Account | null
  }

  async update(userId: string, model: any): Promise<Account> {
    const { file, ...payload }: File & any = model

    let updateDTO: any = {}
    Object.keys(payload).forEach((key) => {
      if (payload[key] !== undefined) updateDTO[key] = payload[key]
    })
    if (updateDTO.role) {
      const { data: currentUser, error: userError }: any = await this.supabase
        .from('accounts')
        .select('role')
        .eq('id', userId)
        .single()
      if (userError) throw userError
      if (
        currentUser.role === 'ADMINISTRADOR' &&
        updateDTO.role !== 'ADMINISTRADOR'
      ) {
        const { data: admins, error: adminsError }: any = await this.supabase
          .from('accounts')
          .select('role')
          .eq('role', 'ADMINISTRADOR')
        if (adminsError) throw adminsError
        if (admins.length === 1) {
          throw new Error('ADMIN_MIN_ERROR')
        }
      }
    }

    // Step 1, Update account details
    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('accounts')
      .update(updateDTO as never)
      .eq('id', userId)
      .select('*')

    if (updateModelError) throw updateModelError

    if (file) {
      return await this.uploadThumbnail(userId, file)
    }

    return updateResponse[0]
  }

  async uploadThumbnail(userId: string, file: File): Promise<Account> {
    // Step 1: Update file
    const fileType: string = file.type.split('/')[1]
    const { data: fileResponse, error: fileError } = await uploadFileAsync(
      'assets',
      `accounts/${userId}/thumbnail-${Date.now()}.${fileType}`,
      file,
    )

    if (fileError) throw fileError

    const image = getFileStorageUrl('assets', fileResponse.path)

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('accounts')
      .update({ imageUrl: image } as never)
      .eq('id', userId)
      .select('*')

    if (updateModelError) throw updateModelError

    return updateResponse[0]
  }

  async deactivate(userId: string): Promise<void> {
    const { data: user, error: userError } = await this.supabase
      .from('accounts')
      .select('role')
      .eq('id', userId)
      .single()
    if (userError) throw userError
    if (user.role === 'ADMINISTRADOR') {
      const { data: admins, error: adminsError }: any = await this.supabase
        .from('accounts')
        .select('role')
        .eq('role', 'ADMINISTRADOR')
      if (adminsError) throw adminsError
      if (admins.length === 1) {
        throw new Error('ADMIN_MIN_ERROR')
      }
    }
    const { data: updateResponse, error: errorResult } = await this.supabase
      .from('accounts')
      .update({ isActive: false } as never)
      .eq('id', userId)
      .select()

    if (errorResult) throw errorResult
  }

  async registerUser(payload: RegisterUserDTO): Promise<Account> {
    const { data: registerResponse, error: registerError } =
      await this.supabase.auth.signUp(payload.supabaseData)

    if (registerError) throw registerError
    if (registerResponse.user === null)
      throw new Error('Registered user in respnse was null')
    // Now we update the user
    return await this.update(registerResponse.user.id, payload.userData)
  }

  async deleteAccount(context: string): Promise<void> {}
}
