export interface ConstantItem {
  readonly value: string
  readonly en: string
  readonly es: string
}

export const languageList: ConstantItem[] = [
  { value: 'es', en: 'Spanish', es: 'Español' },
  { value: 'en', en: 'English', es: 'Inglés' },
]

export const visitorTypeList: ConstantItem[] = [
  { value: 'VISITOR', en: 'VISITOR', es: 'VISITANTE' },
  { value: 'CONTRACTOR', en: 'CONTRACTOR', es: 'CONTRATISTA' },
]

export const appRoleList: ConstantItem[] = [
  { value: 'ADMINISTRADOR', en: 'ADMIN', es: 'ADMINISTRADOR' },
  { value: 'DESAROLLADOR', en: 'DEVELOPER', es: 'DESARROLLADOR' },
  { value: 'AUDITOR', en: 'AUDITOR', es: 'AUDITOR' },
  { value: 'GERENTE', en: 'MANAGER', es: 'GERENTE' },
  { value: 'OPERADOR', en: 'OPERATOR', es: 'OPERADOR' },
  { value: 'CLIENTE', en: 'CLIENT', es: 'CLIENTE' },
]

export const appointmentStatusList: ConstantItem[] = [
  { value: 'EXPECTED', en: 'EXPECTED', es: 'ESPERADO' },
  { value: 'LATE', en: 'LATE', es: 'TARDE' },
  { value: 'CHECKED_IN', en: 'CHECKED IN', es: 'CHEQUEADO' },
  { value: 'CHECKED_OUT', en: 'CHECKED OUT', es: 'SALIDO' },
  { value: 'OVER_STAYED', en: 'OVER STAYED', es: 'NO HA SALIDO' },
  { value: 'UNKNOWN', en: 'UNKNOWN', es: 'DESCONOCIDO' },
]

export const membershipList: ConstantItem[] = [
  { value: 'ADMIN', en: 'ADMIN', es: 'ADMIN' },
  { value: 'MEMBER', en: 'MEMBER', es: 'MIEMBRO' },
]

export const approvalStatusList: ConstantItem[] = [
  { value: 'approved', en: 'APPROVE', es: 'APROBAR' },
  { value: 'rejected', en: 'REJECT', es: 'RECHAZAR' },
]

export const fileExtensions = {
  image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'],
  video: ['mp4', 'ogg', 'webm'],
  text: ['txt'],
  pdf: ['pdf'],
  document: ['doc', 'docx'],
  spreadsheet: ['xls', 'xlsx', 'csv'],
}

export const fullIncidentQuery =
  '*, type:typeId(*, primaryIcon:primaryIconId(id, name), secondaryIcon:secondaryIconId(id, name), riskLevel:riskLevelId(id, name, hexColor), category:categoryId(name), subCategory:subCategoryId(name))'

export const defaultColumns = {
  createdAt:{
    dataField: 'createdAt',
    dataType: 'datetime',
    captionSelector: 'created-at',
    caption: '',
    cellTemplate: 'dateTemplate',
    allowHeaderFiltering: false,
    show: true,
  },
  name: {
    dataField: 'name',
    dataType: 'string',
    captionSelector: 'name',
    caption: '',
    allowHeaderFiltering: false,
    show: true,
  },
  description: {
    dataField: 'description',
    dataType: 'string',
    captionSelector: 'description',
    caption: '',
    show: true,
    allowHeaderFiltering: false,
  },
  isActive: {
    dataField: 'isActive',
    dataType: 'boolean',
    captionSelector: 'is-active',
    caption: '',
    allowHeaderFiltering: true,
    show: true,
  },
  show: {
    dataField: 'show',
    dataType: 'boolean',
    captionSelector: 'show',
    caption: '',
    allowHeaderFiltering: true,
    show: true,
  },
  actions: {
    dataField: 'id',
    captionSelector: 'actions',
    caption: '',
    cellTemplate: 'actionsTemplate',
    width: 140,
    allowHeaderFiltering: false,
    show: true,
  },
}
