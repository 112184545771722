import type {
  FollowUp,
  FollowUpCreateDTO,
  FollowUpMedia,
  FollowUpMediaCreateDTO,
  FollowUpMediaUpdateDTO,
  FollowUpSite,
  FollowUpSiteCreateDTO,
  FollowUpUpdateDTO,
  InvolvedParty,
  InvolvedPartyCategory,
  InvolvedPartyCategoryCreateDTO,
  InvolvedPartyCategoryUpdateDTO,
  InvolvedPartyCreateDTO,
  InvolvedPartyType,
  InvolvedPartyTypeCreateDTO,
  InvolvedPartyTypeUpdateDTO,
  InvolvedPartyUpdateDTO,
  Site,
  SiteCreateDTO,
  SiteUpdateDTO,
  Victim,
  VictimCreateDTO,
  VictimType,
  VictimTypeCreateDTO,
  VictimTypeUpdateDTO,
} from "~/types";

import { SupabaseClient } from "@supabase/supabase-js";

import { v4 as uuid } from "uuid";

export declare abstract class IFollowUpService {
  abstract getAllFollowUps(): Promise<FollowUp[]>;
  abstract getIncidentFollowUp(incidentId: string): Promise<FollowUp>;
  abstract getFollowUpById(followUpId: string): Promise<FollowUp>;
  abstract createFollowUp(data: FollowUpCreateDTO): Promise<FollowUp>;
  abstract updateFollowUp(
    followUpId: string,
    data: FollowUpUpdateDTO,
  ): Promise<FollowUp>;
  abstract logFollowUpUpdate(
    updatedBy: string,
    followUpId: string,
  ): Promise<FollowUp>;
  abstract deleteFollowUp(followUpId: string): Promise<void>;

  abstract getVictimTypes(): Promise<VictimType[]>;
  abstract getVictimType(victimTypeId: string): Promise<VictimType>;
  abstract createVictimType(data: VictimTypeCreateDTO): Promise<VictimType>;
  abstract updateVictimType(
    victimTypeId: string,
    data: VictimTypeUpdateDTO,
  ): Promise<VictimType>;
  abstract deleteVictimType(victimTypeId: string): Promise<void>;

  abstract getFollowUpVictims(followUpId: string): Promise<Victim[]>;
  abstract getVictim(victimId: string): Promise<Victim>;
  abstract createVictim(data: VictimCreateDTO): Promise<Victim>;
  abstract deleteVictim(
    victimId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void>;
  abstract getInvolvedPartyCategories(): Promise<InvolvedPartyCategory[]>;
  abstract getInvolvedPartyCategory(
    involvedPartyCategoryId: string,
  ): Promise<InvolvedPartyCategory>;
  abstract createInvolvedPartyCategory(
    data: InvolvedPartyCategoryCreateDTO,
  ): Promise<InvolvedPartyCategory>;
  abstract updateInvolvedPartyCategory(
    involvedPartyCategoryId: string,
    data: InvolvedPartyCategoryUpdateDTO,
  ): Promise<InvolvedPartyCategory>;
  abstract deleteInvolvedPartyCategory(
    involvedPartyCategoryId: string,
  ): Promise<void>;

  abstract getInvolvedPartyTypes(): Promise<InvolvedPartyType[]>;
  abstract getInvolvedPartyType(
    involvedPartyTypeId: string,
  ): Promise<InvolvedPartyType>;
  abstract createInvolvedPartyType(
    data: InvolvedPartyTypeCreateDTO,
  ): Promise<InvolvedPartyType>;
  abstract updateInvolvedPartyType(
    involvedPartyTypeId: string,
    data: InvolvedPartyTypeUpdateDTO,
  ): Promise<InvolvedPartyType>;
  abstract deleteInvolvedPartyType(
    involvedPartyTypeId: string,
  ): Promise<void>;

  abstract getFollowUpInvolvedParties(
    followUpId: string,
  ): Promise<InvolvedParty[]>;
  abstract getInvolvedParty(
    involvedPartyId: string,
  ): Promise<InvolvedParty>;
  abstract createInvolvedParty(
    data: InvolvedPartyCreateDTO,
  ): Promise<InvolvedParty>;
  abstract updateInvolvedParty(
    involvedPartyId: string,
    followUpId: string,
    data: InvolvedPartyUpdateDTO,
  ): Promise<InvolvedParty>;
  abstract deleteInvolvedParty(
    involvedPartyId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void>;

  abstract getSites(): Promise<Site[]>;
  abstract getSite(siteId: string): Promise<Site>;
  abstract createSite(data: SiteCreateDTO): Promise<Site>;
  abstract updateSite(
    siteId: string,
    data: SiteUpdateDTO,
  ): Promise<Site>;
  abstract deleteSite(siteId: string): Promise<void>;

  abstract getFollowUpSites(
    followUpId: string,
  ): Promise<FollowUpSite[]>;
  abstract getFollowUpSite(siteId: string): Promise<FollowUpSite>;
  abstract createFollowUpSite(
    data: FollowUpSiteCreateDTO,
  ): Promise<FollowUpSite>;
  abstract deleteFollowUpSite(
    siteId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void>;

  abstract getFollowUpMedia(
    followUpId: string,
  ): Promise<FollowUpMedia[]>;
  abstract getFollowUpMediaItem(
    mediaId: string,
  ): Promise<FollowUpMedia>;
  abstract createFollowUpMedia(
    data: FollowUpMediaCreateDTO,
  ): Promise<FollowUpMedia>;
  abstract updateFollowUpMedia(
    mediaId: string,
    followUpId: string,
    data: FollowUpMediaUpdateDTO,
  ): Promise<void>;
  abstract deleteFollowUpMedia(
    mediaId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void>;
}

export class FollowUpServiceImpl implements IFollowUpService {
  private readonly supabase: SupabaseClient;
  private readonly bucket: string = "follow-up-media";
  //MIME string: image/*,video/*,application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient;
  }

  async getAllFollowUps(): Promise<FollowUp[]> {
    const { data, error } = await this.supabase.from("followUp").select("*");
    if (error) throw error;
    return data ?? [];
  }

  async getIncidentFollowUp(incidentId: string): Promise<FollowUp> {
    const { data, error } = await this.supabase
      .from("followUp")
      .select("*, incident:incidentId(id, eventEnd)")
      .eq("incidentId", incidentId)
      .single();
    if (error) throw error;
    return data;
  }

  async getFollowUpById(followUpId: string): Promise<FollowUp> {
    const { data, error } = await this.supabase
      .from("followUp")
      .select("*")
      .eq("id", followUpId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createFollowUp(data: FollowUpCreateDTO): Promise<FollowUp> {
    const { data: followUp, error } = await this.supabase
      .from("followUp")
      .insert(data)
      .single();
    if (error) throw error;
    return followUp ?? null;
  }

  async updateFollowUp(
    followUpId: string,
    data: any,
  ): Promise<FollowUp> {
    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });
    const { data: followUp, error } = await this.supabase
      .from("followUp")
      .update(updateDTO)
      .eq("id", followUpId)
      .single();
    if (error) throw error;
    return followUp ?? null;
  }

  async logFollowUpUpdate(
    updatedBy: string,
    followUpId: string,
  ): Promise<FollowUp> {
    const { data: followUp, error } = await this.supabase
      .from("followUp")
      .update({ updatedBy, updatedAt: new Date() })
      .eq("id", followUpId)
      .single();
    if (error) throw error;
    return followUp ?? null;
  }

  async deleteFollowUp(followUpId: string): Promise<void> {
    const { error } = await this.supabase
      .from("followUp")
      .delete()
      .eq("id", followUpId);
    if (error) throw error;
  }

  async getVictimTypes(): Promise<VictimType[]> {
    const { data, error } = await this.supabase
      .from("victimType")
      .select("*")
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getVictimType(victimTypeId: string): Promise<VictimType> {
    const { data, error } = await this.supabase
      .from("victimType")
      .select("*")
      .eq("id", victimTypeId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createVictimType(data: VictimTypeCreateDTO): Promise<VictimType> {
    const { data: victimType, error } = await this.supabase
      .from("victimType")
      .insert(data)
      .single();
    if (error) throw error;
    return victimType ?? null;
  }

  async updateVictimType(
    victimTypeId: string,
    data: any,
  ): Promise<VictimType> {
    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });
    const { data: victimType, error } = await this.supabase
      .from("victimType")
      .update(updateDTO)
      .eq("id", victimTypeId)
      .single();
    if (error) throw error;
    return victimType ?? null;
  }

  async deleteVictimType(victimTypeId: string): Promise<void> {
    const { error } = await this.supabase
      .from("victimType")
      .delete()
      .eq("id", victimTypeId);
    if (error) throw error;
  }

  async getFollowUpVictims(followUpId: string): Promise<Victim[]> {
    const { data, error } = await this.supabase
      .from("victim")
      .select("*, type:victimTypeId(id, name)")
      .eq("followUpId", followUpId)
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getVictim(victimId: string): Promise<Victim> {
    const { data, error } = await this.supabase
      .from("victim")
      .select("*")
      .eq("id", victimId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createVictim(data: VictimCreateDTO): Promise<Victim> {
    const { data: victim, error } = await this.supabase
      .from("victim")
      .insert(data)
      .single();
    if (error) throw error;
    await this.logFollowUpUpdate(data.createdBy, data.followUpId);
    return victim ?? null;
  }

  async deleteVictim(
    victimId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void> {
    const { error } = await this.supabase
      .from("victim")
      .delete()
      .eq("id", victimId);
    if (error) throw error;
    await this.logFollowUpUpdate(deletedBy, followUpId);
  }

  async getInvolvedPartyCategories(): Promise<InvolvedPartyCategory[]> {
    const { data, error } = await this.supabase
      .from("involvedPartyCategory")
      .select("*")
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getInvolvedPartyCategory(
    involvedPartyCategoryId: string,
  ): Promise<InvolvedPartyCategory> {
    const { data, error } = await this.supabase
      .from("involvedPartyCategory")
      .select("*")
      .eq("id", involvedPartyCategoryId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createInvolvedPartyCategory(
    data: InvolvedPartyCategoryCreateDTO,
  ): Promise<InvolvedPartyCategory> {
    const { data: involvedPartyCategory, error } = await this.supabase
      .from("involvedPartyCategory")
      .insert(data)
      .single();
    if (error) throw error;
    return involvedPartyCategory ?? null;
  }

  async updateInvolvedPartyCategory(
    involvedPartyCategoryId: string,
    data: any,
  ): Promise<InvolvedPartyCategory> {
    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });
    const { data: involvedPartyCategory, error } = await this.supabase
      .from("involvedPartyCategory")
      .update(updateDTO)
      .eq("id", involvedPartyCategoryId)
      .single();
    if (error) throw error;
    return involvedPartyCategory ?? null;
  }

  async deleteInvolvedPartyCategory(
    involvedPartyCategoryId: string,
  ): Promise<void> {
    const { error } = await this.supabase
      .from("involvedPartyCategory")
      .delete()
      .eq("id", involvedPartyCategoryId);
    if (error) throw error;
  }

  async getInvolvedPartyTypes(): Promise<InvolvedPartyType[]> {
    const { data, error } = await this.supabase
      .from("involvedPartyType")
      .select("*")
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getInvolvedPartyType(
    involvedPartyTypeId: string,
  ): Promise<InvolvedPartyType> {
    const { data, error } = await this.supabase
      .from("involvedPartyType")
      .select("*")
      .eq("id", involvedPartyTypeId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createInvolvedPartyType(
    data: InvolvedPartyTypeCreateDTO,
  ): Promise<InvolvedPartyType> {
    const { data: involvedPartyType, error } = await this.supabase
      .from("involvedPartyType")
      .insert(data)
      .single();
    if (error) throw error;
    return involvedPartyType ?? null;
  }

  async updateInvolvedPartyType(
    involvedPartyTypeId: string,
    data: any,
  ): Promise<InvolvedPartyType> {
    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });
    const { data: involvedPartyType, error } = await this.supabase
      .from("involvedPartyType")
      .update(updateDTO)
      .eq("id", involvedPartyTypeId)
      .single();
    if (error) throw error;
    return involvedPartyType ?? null;
  }

  async deleteInvolvedPartyType(
    involvedPartyTypeId: string,
  ): Promise<void> {
    const { error } = await this.supabase
      .from("involvedPartyType")
      .delete()
      .eq("id", involvedPartyTypeId);
    if (error) throw error;
  }

  async getFollowUpInvolvedParties(
    followUpId: string,
  ): Promise<InvolvedParty[]> {
    const { data, error } = await this.supabase
      .from("involvedParty")
      .select(
        "*, involvedPartyType:involvedPartyTypeId(id, name, category:categoryId(id, name))",
      )
      .eq("followUpId", followUpId)
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getInvolvedParty(
    involvedPartyId: string,
  ): Promise<InvolvedParty> {
    const { data, error } = await this.supabase
      .from("involvedParty")
      .select("*")
      .eq("id", involvedPartyId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createInvolvedParty(
    data: InvolvedPartyCreateDTO,
  ): Promise<InvolvedParty> {
    const { data: involvedParty, error } = await this.supabase
      .from("involvedParty")
      .insert(data)
      .single();
    if (error) throw error;
    await this.logFollowUpUpdate(data.createdBy, data.followUpId);
    return involvedParty ?? null;
  }

  async updateInvolvedParty(
    involvedPartyId: string,
    followUpId: string,
    data: any,
  ): Promise<InvolvedParty> {
    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });
    const { data: involvedParty, error } = await this.supabase
      .from("involvedParty")
      .update(updateDTO)
      .eq("id", involvedPartyId)
      .single();
    if (error) throw error;
    await this.logFollowUpUpdate(data.updatedBy, followUpId);
    return involvedParty ?? null;
  }

  async deleteInvolvedParty(
    involvedPartyId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void> {
    const { error } = await this.supabase
      .from("involvedParty")
      .delete()
      .eq("id", involvedPartyId);
    if (error) throw error;
    await this.logFollowUpUpdate(deletedBy, followUpId);
  }

  async getSites(): Promise<Site[]> {
    const { data, error } = await this.supabase
      .from("site")
      .select("*")
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getSite(siteId: string): Promise<Site> {
    const { data, error } = await this.supabase
      .from("site")
      .select("*")
      .eq("id", siteId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createSite(data: SiteCreateDTO): Promise<Site> {
    const { data: site, error } = await this.supabase
      .from("site")
      .insert(data)
      .single();
    if (error) throw error;
    return site ?? null;
  }

  async updateSite(siteId: string, data: any): Promise<Site> {
    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });
    const { data: site, error } = await this.supabase
      .from("site")
      .update(updateDTO)
      .eq("id", siteId)
      .single();
    if (error) throw error;
    return site ?? null;
  }

  async deleteSite(siteId: string): Promise<void> {
    const { error } = await this.supabase.from("site").delete().eq(
      "id",
      siteId,
    );
    if (error) throw error;
  }

  async getFollowUpSites(followUpId: string): Promise<FollowUpSite[]> {
    const { data, error } = await this.supabase
      .from("followUpSite")
      .select("*, site:siteId(id, name)")
      .eq("followUpId", followUpId)
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getFollowUpSite(siteId: string): Promise<FollowUpSite> {
    const { data, error } = await this.supabase
      .from("followUpSite")
      .select("*")
      .eq("id", siteId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createFollowUpSite(data: FollowUpSiteCreateDTO): Promise<FollowUpSite> {
    const { data: followUpSite, error } = await this.supabase
      .from("followUpSite")
      .insert(data)
      .single();
    if (error) throw error;
    await this.logFollowUpUpdate(data.createdBy, data.followUpId);
    return followUpSite ?? null;
  }

  async deleteFollowUpSite(
    siteId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void> {
    const { error } = await this.supabase
      .from("followUpSite")
      .delete()
      .eq("id", siteId);
    if (error) throw error;
    await this.logFollowUpUpdate(deletedBy, followUpId);
  }

  async getFollowUpMedia(
    followUpId: string,
  ): Promise<FollowUpMedia[]> {
    const { data, error } = await this.supabase
      .from("followUpMedia")
      .select("*")
      .eq("followUpId", followUpId)
      .order("createdAt", { ascending: true });
    if (error) throw error;
    return data ?? [];
  }

  async getFollowUpMediaItem(mediaId: string): Promise<FollowUpMedia> {
    const { data, error } = await this.supabase
      .from("followUpMedia")
      .select("*")
      .eq("id", mediaId)
      .single();
    if (error) throw error;
    return data ?? null;
  }

  async createFollowUpMedia(
    data: FollowUpMediaCreateDTO,
  ): Promise<FollowUpMedia> {
    let mediaUrl: string | undefined;
    if (data.media) {
      mediaUrl = await this.uploadMedia(data.media);
    }
    const createDTO: any = {
      followUpId: data.followUpId,
      mediaUrl: mediaUrl,
      createdBy: data.createdBy,
    };
    const { data: followUpMedia, error } = await this.supabase
      .from("followUpMedia")
      .insert(createDTO)
      .single();
    if (error) throw error;
    await this.logFollowUpUpdate(data.createdBy, data.followUpId);
    return followUpMedia ?? null;
  }

  private async uploadMedia(file: File) {
    const fileName = `${Date.now()}-${file?.name ?? uuid()}`;
    const { data: fileUploadResponse, error: fileError } =
      await uploadFileAsync(this.bucket, fileName, file);

    if (fileError) throw fileError;

    return getFileStorageUrl(this.bucket, fileUploadResponse!.path);
  }

  async updateFollowUpMedia(
    mediaId: string,
    followUpId: string,
    data: any,
  ): Promise<void> {
    const { error } = await this.supabase
      .from("followUpMedia")
      .update({ show: data.show })
      .eq("id", mediaId);
    if (error) throw error;
    await this.logFollowUpUpdate(data.updatedBy, followUpId);
  }

  async deleteFollowUpMedia(
    mediaId: string,
    deletedBy: string,
    followUpId: string,
  ): Promise<void> {
    const { error } = await this.supabase
      .from("followUpMedia")
      .delete()
      .eq("id", mediaId)
      .single();
    if (error) throw error;
    await this.logFollowUpUpdate(deletedBy, followUpId);
  }
}
