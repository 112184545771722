import axios, { type AxiosInstance } from 'axios';
import type {
    IncidentWeatherData,
    OpenWeatherApiOptions,
    WeatherDataResponse,
    WeatherRequest
} from '~/types/openweatherapi';
import type { Incident } from '~/types';


export declare abstract class IWeatherService {
    abstract getIncidentWeatherData(incident: Incident): Promise<IncidentWeatherData | null>;
}

export class WeatherServiceImpl implements IWeatherService {
    private readonly client: AxiosInstance;
    private readonly apiKey: string;

    constructor() {
        const opts = useRuntimeConfig()?.public?.openWeatherApi as OpenWeatherApiOptions;

        this.apiKey = opts.apiKey;
        this.client = axios.create({
            baseURL: opts.url,
        });
    }

    async fetchWeather(request: WeatherRequest) {
        try {
            const response = await this.client.request({
                url: '/weather',
                method: 'GET',
                params: {
                    lat: request.lat,
                    lon: request.lon,
                    appid: this.apiKey,
                    units: request.units ?? 'metric'
                }
            });

            return response.data || null;
        } catch (e) {
            console.error(e);
            return null;
        }
    }


    async getIncidentWeatherData(incident: Incident): Promise<IncidentWeatherData | null> {
        const weatherData = await this.fetchWeather({
            lat: incident.latitude.toString(),
            lon: incident.longitude.toString(),
            units: 'metric'
        })

        if(!weatherData) return null;

        return {
            ...extractWeatherData(weatherData) as any,
            incidentId: incident.id,
            unit: 'metric',
            raw: weatherData,
        } as IncidentWeatherData;
    }
}

export function extractWeatherData(data?: WeatherDataResponse) {
  if(!data) return null;

  return {
        temp: data?.main?.temp ?? 0,
        temp_feels_like: data?.main?.feels_like ?? 0,
        humidity: data?.main?.humidity ?? 0,
        pressure: data?.main?.pressure ?? 0,
        wind_speed: data?.wind?.speed ?? 0,
        rain_amount: data?.rain?.['1h'] ?? 0,
        snow_amount: data?.snow?.['1h'] ?? 0,
        cloudiness: data.clouds?.all ?? 0,
        unit: 'metric', // By default,
        country: data?.sys?.country,
        area_name: data?.name,
        timezone: data?.timezone ?? 0
  }
}
