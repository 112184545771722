import type { Plugin as NuxtPlugin } from '#app'
import posthog from 'posthog-js'

interface PosthogConfig {
  host: string
  key: string
}

const plugin: NuxtPlugin = defineNuxtPlugin((nuxtApp) => {
  const opts = useRuntimeConfig()?.public?.posthog as PosthogConfig
  const mode = useRuntimeConfig().public.mode

  if (!opts.key) return

  const posthogClient = posthog.init(opts.key, {
    api_host: opts.host || 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog: any) => {
      if (mode === 'development') posthog.debug()
    },
  })

  const router = useRouter()
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath,
      })
    })
  })

  return nuxtApp.provide('logger', posthogClient)
})

export default plugin
