import revive_payload_client_zRPa2W8x6a from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.2_eslint@8.57.0_rollup@4.14.1_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_w40VT39kNe from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.2_eslint@8.57.0_rollup@4.14.1_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ewBD0LYpT8 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.2_eslint@8.57.0_rollup@4.14.1_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_p4O49EVuNH from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.3.2_rollup@4.14.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_7vyyvudyIe from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.2_eslint@8.57.0_rollup@4.14.1_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_It2jKkQrft from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.2_eslint@8.57.0_rollup@4.14.1_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_jpkevNXmPb from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.14.1_typescript@5.4.5_vue@3.4.33/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.app/.nuxt/components.plugin.mjs";
import prefetch_client_CvwEH6wJCT from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.2_eslint@8.57.0_rollup@4.14.1_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_exNVq2O2Nn from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_8ZQh7hY0br from "/opt/render/project/src/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_vue@3.4.21/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import i18n_e3fckahYtq from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.14.1_vue@3.4.33/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_redirect_nJbazy9QVe from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.3.2_rollup@4.14.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import chunk_reload_client_eefoLDBCdM from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@types+node@22.5.2_eslint@8.57.0_rollup@4.14.1_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_GUyoABfi4a from "/opt/render/project/src/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.4.11_pinia@2.1.7_rollup@4.14.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_yBPzJQtdia from "/opt/render/project/src/layers/tairo/plugins/directives.ts";
import _00_logging_client_rLDKabzlzG from "/opt/render/project/src/.app/plugins/00.logging.client.ts";
import dx_client_pUj1dsd230 from "/opt/render/project/src/.app/plugins/dx.client.ts";
import eventbus_client_hXjVpwcj1U from "/opt/render/project/src/.app/plugins/eventbus.client.ts";
import service_workers_client_oduUtRk6M5 from "/opt/render/project/src/.app/plugins/service-workers.client.ts";
import services_client_NIWiCMi26b from "/opt/render/project/src/.app/plugins/services.client.ts";
export default [
  revive_payload_client_zRPa2W8x6a,
  unhead_w40VT39kNe,
  router_ewBD0LYpT8,
  supabase_client_p4O49EVuNH,
  payload_client_7vyyvudyIe,
  check_outdated_build_client_It2jKkQrft,
  plugin_vue3_jpkevNXmPb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CvwEH6wJCT,
  plugin_client_exNVq2O2Nn,
  plugin_8ZQh7hY0br,
  i18n_e3fckahYtq,
  auth_redirect_nJbazy9QVe,
  chunk_reload_client_eefoLDBCdM,
  plugin_GUyoABfi4a,
  directives_yBPzJQtdia,
  _00_logging_client_rLDKabzlzG,
  dx_client_pUj1dsd230,
  eventbus_client_hXjVpwcj1U,
  service_workers_client_oduUtRk6M5,
  services_client_NIWiCMi26b
]