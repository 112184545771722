import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAppLogo, LazyCircularMenuActivity, LazyCircularMenuLanguage, LazyCircularMenuNotifications, LazyCollapseNavigationFooter, LazyCollapseNavigationHeader, LazyPanelAddUser, LazyPanelAppIcon, LazyPanelAsset, LazyPanelCategory, LazyPanelEmergencyContact, LazyPanelGalleryFilter, LazyPanelGroup, LazyPanelIncidentDetails, LazyPanelIncidentType, LazyPanelLanguage, LazyPanelMapFilter, LazyPanelMemberUpdate, LazyPanelNotification, LazyPanelRecommendation, LazyPanelReport, LazyPanelRiskLevel, LazyPanelSearch, LazyPanelSource, LazyPanelSourceEntry, LazyPanelSubCategory, LazyPanelTask, LazyPanelUserGroupEdit, LazyPanelUserPasswordReset, LazySlimSidebar, LazySubsidebarDashboards, LazySubsidebarLayouts, LazyThemeToggle, LazyToolbarActivity, LazyToolbarLanguage, LazyToolbarNotifications, LazyToolbarUserAccountMenu, LazyUserAccountMenu, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AppLogo", LazyAppLogo],
["CircularMenuActivity", LazyCircularMenuActivity],
["CircularMenuLanguage", LazyCircularMenuLanguage],
["CircularMenuNotifications", LazyCircularMenuNotifications],
["CollapseNavigationFooter", LazyCollapseNavigationFooter],
["CollapseNavigationHeader", LazyCollapseNavigationHeader],
["PanelAddUser", LazyPanelAddUser],
["PanelAppIcon", LazyPanelAppIcon],
["PanelAsset", LazyPanelAsset],
["PanelCategory", LazyPanelCategory],
["PanelEmergencyContact", LazyPanelEmergencyContact],
["PanelGalleryFilter", LazyPanelGalleryFilter],
["PanelGroup", LazyPanelGroup],
["PanelIncidentDetails", LazyPanelIncidentDetails],
["PanelIncidentType", LazyPanelIncidentType],
["PanelLanguage", LazyPanelLanguage],
["PanelMapFilter", LazyPanelMapFilter],
["PanelMemberUpdate", LazyPanelMemberUpdate],
["PanelNotification", LazyPanelNotification],
["PanelRecommendation", LazyPanelRecommendation],
["PanelReport", LazyPanelReport],
["PanelRiskLevel", LazyPanelRiskLevel],
["PanelSearch", LazyPanelSearch],
["PanelSource", LazyPanelSource],
["PanelSourceEntry", LazyPanelSourceEntry],
["PanelSubCategory", LazyPanelSubCategory],
["PanelTask", LazyPanelTask],
["PanelUserGroupEdit", LazyPanelUserGroupEdit],
["PanelUserPasswordReset", LazyPanelUserPasswordReset],
["SlimSidebar", LazySlimSidebar],
["SubsidebarDashboards", LazySubsidebarDashboards],
["SubsidebarLayouts", LazySubsidebarLayouts],
["ThemeToggle", LazyThemeToggle],
["ToolbarActivity", LazyToolbarActivity],
["ToolbarLanguage", LazyToolbarLanguage],
["ToolbarNotifications", LazyToolbarNotifications],
["ToolbarUserAccountMenu", LazyToolbarUserAccountMenu],
["UserAccountMenu", LazyUserAccountMenu],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
