import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxTexts,
  DxItem,
  DxLoadPanel,
  DxLookup,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSorting,
  DxSearchPanel,
  DxSelection,
  DxToolbar,
  DxColumnChooser,
  DxPosition,
  DxFilterRow,
  DxMasterDetail,
  DxHeaderFilter,
  DxRequiredRule,
  DxStringLengthRule,
  DxPatternRule,
  DxNumericRule,
} from 'devextreme-vue/data-grid'
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup'
import { DxSelectBox } from 'devextreme-vue/select-box'
import { DxButton } from 'devextreme-vue/button'
import { DxList } from 'devextreme-vue/list'
import { DxColorBox } from 'devextreme-vue/color-box'
import { DxDateBox } from 'devextreme-vue/date-box'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box'
import { DxTabPanel } from 'devextreme-vue/tab-panel'
import { DxTreeView } from 'devextreme-vue/tree-view'
import { DxFileUploader } from 'devextreme-vue/file-uploader'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp
    .component('DxDataGrid', DxDataGrid)
    .component('DxColumn', DxColumn)
    .component('DxPaging', DxPaging)
    .component('DxPager', DxPager)
    .component('DxFilterRow', DxFilterRow)
    .component('DxHeaderFilter', DxHeaderFilter)
    .component('DxEditing', DxEditing)
    .component('DxTexts', DxTexts)
    .component('DxSearchPanel', DxSearchPanel)
    .component('DxItem', DxItem)
    .component('DxToolbar', DxToolbar)
    .component('DxLoadPanel', DxLoadPanel)
    .component('DxSorting', DxSorting)
    .component('DxMasterDetail', DxMasterDetail)
    .component('DxSelectBox', DxSelectBox)
    .component('DxSelection', DxSelection)
    .component('DxLookup', DxLookup)
    .component('DxScrolling', DxScrolling)
    .component('DxButton', DxButton)
    .component('DxList', DxList)
    .component('DxColorBox', DxColorBox)
    .component('DxColumnChooser', DxColumnChooser)
    .component('DxPosition', DxPosition)
    .component('DxDateBox', DxDateBox)
    .component('DxDropDownBox', DxDropDownBox)
    .component('DxTabPanel', DxTabPanel)
    .component('DxTreeView', DxTreeView)
    .component('DxRequiredRule', DxRequiredRule)
    .component('DxStringLengthRule', DxStringLengthRule)
    .component('DxPatternRule', DxPatternRule)
    .component('DxNumericRule', DxNumericRule)
    .component('DxFileUploader', DxFileUploader)
    .component('DxPopup', DxPopup)
    .component('DxToolbarItem', DxToolbarItem)
  // .component('',)
})
