import type {QueryOptions} from "~/services/types";
import type {Category} from "~/types";
import type {PostgrestError} from "@supabase/postgrest-js";
import {SupabaseClient} from "@supabase/supabase-js";

export declare abstract class ICategoriesService {
  abstract getAll(options?: QueryOptions): Promise<Category[]>
  abstract get(categoryId: string): Promise<Category | null>
  abstract update(
    categoryId: string,
    data: any
  ): Promise<Category>
  delete(categoryId: string): Promise<void>
  abstract create(model: any): Promise<Category>
}

export class CategoriesServiceImpl implements ICategoriesService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }
  async delete(categoryId: string) {
    const { error } = await this.supabase
      .from('category')
      .delete()
      .eq('id', categoryId)
    if (error) throw error
  }

  async get(categoryId: string): Promise<Category | null> {
    const response = await this.supabase
      .from('category')
      .select('*')
      .eq('id', categoryId)
      .limit(1)
      .single()
    return response.data as Category | null
  }

  async getAll(options?: QueryOptions): Promise<Category[]> {
    const { data, error } = await this.supabase.from('category').select('*')

    if (error) throw error

    return data as unknown as Category[]
  }

  async update(categoryId: string, data: any): Promise<Category> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('category')
      .update(updateDTO as never)
      .eq('id', categoryId)
      .select('*')

    if (updateModelError) {
      throw updateModelError
    }

    return updateResponse[0]
  }

  async create(model: any): Promise<Category> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('category')
      .insert([updateDTO as never])
      .select()

    if (insertModelError) throw insertModelError

    return insertResponse[0]
  }
}
