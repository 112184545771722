/**
 * This file is used to configure the app
 *
 * If you have the "Cannot find name 'defineAppConfig'.ts(2304)" error
 * update the root tsconfig.json file to include the following:
 *
 *  "extends": "./.app/.nuxt/tsconfig.json"
 *
 */

export default defineAppConfig({
  nuxtIcon: {},
  tairo: {
    title: 'Situational Awareness',
    collapse: {
      toolbar: {
        enabled: true,
        showTitle: true,
        showNavBurger: true,
        tools: [
          {
            component: 'ThemeToggle',
          },
          {
            component: 'ToolbarLanguage',
          },
          {
            component: 'ToolbarUserAccountMenu',
          },
        ],
      },
      circularMenu: {
        enabled: true,
        tools: [
          {
            component: 'ThemeToggle',
          },
          {
            component: 'CircularMenuLanguage',
          },
        ],
      },
      navigation: {
        enabled: true,
        header: {
          component: 'CollapseNavigationHeader',
        },
        footer: {
          component: 'CollapseNavigationFooter',
        },
        items: [
          {
            name: 'Active Incidents',
            icon: {
              name: 'material-symbols:interactive-space-outline',
              class: 'w-5 h-5',
            },
            to: '/manage/incidents/active',
            activePath: '/manage/incidents/active',
          },
          {
            name: 'Incidents Map',
            icon: {
              name: 'mdi:card-account-details-outline',
              class: 'w-5 h-5',
            },
            to: '/manage/incidents',
            activePath: '/manage/incidents',
          },
          {
            name: 'Incidents',
            icon: {
              name: 'material-symbols:motion-sensor-alert',
              class: 'w-5 h-5',
            },
            to: '/manage/incidents/table',
            activePath: '/manage/incidents/table',
          },
          {
            name: 'Choropleth',
            icon: {
              name: 'oui:heatmap',
              class: 'w-5 h-5',
            },
            to: '/manage/reports/choropleth',
            activePath: '/manage/reports/choropleth',
          },
          {
            name: 'Divider',
            divider: true,
          },
          {
            name: 'Settings',
            icon: { name: 'ph:gear-six-duotone', class: 'w-5 h-5' },
            to: '/manage',
            activePath: '/manage',
            position: 'end',
          },
        ],
      },
    },
    panels: [
      {
        name: 'language',
        position: 'right',
        component: 'PanelLanguage',
      },
      {
        name: 'member-update',
        position: 'right',
        component: 'PanelMemberUpdate',
      },
      {
        name: 'notification-panel',
        position: 'right',
        component: 'PanelNotification',
      },
      {
        name: 'category-panel',
        position: 'right',
        component: 'PanelCategory',
      },
      {
        name: 'subcategory-panel',
        position: 'right',
        component: 'PanelSubCategory',
      },
      {
        name: 'risk-level-panel',
        position: 'right',
        component: 'PanelRiskLevel',
      },
      {
        name: 'recommendation-panel',
        position: 'right',
        component: 'PanelRecommendation',
      },
      {
        name: 'incident-type-panel',
        position: 'right',
        component: 'PanelIncidentType',
      },
      {
        name: 'app-icon-panel',
        position: 'right',
        component: 'PanelAppIcon',
      },
      {
        name: 'group-panel',
        position: 'right',
        component: 'PanelGroup',
      },
      {
        name: 'sources-panel',
        position: 'right',
        component: 'PanelSource',
      },
      {
        name: 'source-entry-panel',
        position: 'right',
        component: 'PanelSourceEntry',
      },
      {
        name: 'report-panel',
        position: 'right',
        component: 'PanelReport',
      },
      {
        name: 'map-filter-panel',
        position: 'right',
        component: 'PanelMapFilter',
      },
      {
        name: 'gallery-filter-panel',
        position: 'right',
        component: 'PanelGalleryFilter',
      },
      {
        name: 'emergency-contact-panel',
        position: 'right',
        component: 'PanelEmergencyContact',
      },
      {
        name: 'add-user-panel',
        position: 'right',
        component: 'PanelAddUser',
      },
      {
        name: 'safe-zone-panel',
        position: 'right',
        component: 'PanelSafeZone',
      },
      {
        name: 'incident-details-panel',
        position: 'right',
        component: 'PanelIncidentDetails',
      },
      {
        name: 'user-group-edit-panel',
        position: 'right',
        component: 'PanelUserGroupEdit',
      },
      {
        name: 'user-password-reset-panel',
        position: 'right',
        component: 'PanelUserPasswordReset',
      },
    ],
    error: {
      logo: {
        component: 'img',
        props: {
          src: '/img/illustrations/system/404.svg',
          class: 'relative z-20 w-full max-w-lg mx-auto',
        },
      },
    },
  },
})
