import { polygon, Position } from '@turf/helpers'
import centroid from '@turf/centroid'

export interface Coordinate {
  latitude: number
  longitude: number
}

export function getGeoPolygonCenter(coordinates: Position[][]): Coordinate {
  const turfPolygon = polygon(coordinates)
  const turfCentroid = centroid(turfPolygon)

  const centerLatitude = turfCentroid.geometry!.coordinates[1]
  const centerLongitude = turfCentroid.geometry!.coordinates[0]

  return {
    latitude: centerLatitude,
    longitude: centerLongitude,
  }
}

/**
 * Returns a simple coordinate array with fixed decimal places
 * @param coordinates
 * @param fixed
 */
export function getSimpleCoords({
    latitude = 0,
    longitude = 0,
    fixed = null,
    inverted = false,
  }): number[] {
  const lat = fixed ? latitude.toFixed(fixed) : latitude
  const long = fixed ? longitude.toFixed(fixed) : longitude

  return inverted ? [Number(lat), Number(long)] : [Number(long), Number(lat)]
}
