export default defineNuxtPlugin(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/map-tile-worker.js')
            .then((registration) => {
                console.log('Service Worker for Map tile registration successful with scope: ', registration.scope);
            })
            .catch((error) => {
                console.error('Service Worker for Map tile registration failed: ', error);
            });
    }
});