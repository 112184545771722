import type { QueryOptions } from '~/services/types'
import type { AppIcon } from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { SupabaseClient } from '@supabase/supabase-js'
import type { AppIconCreateDTO } from "~/types";

export declare abstract class IAppIconsService {
  abstract getAll(options?: QueryOptions): Promise<AppIcon[]>

  abstract get(iconId: string): Promise<AppIcon | null>

  abstract update(iconId: string, data: any): Promise<AppIcon>

  delete(iconId: string): Promise<void>

  abstract create(model: AppIconCreateDTO): Promise<AppIcon>
}

export class AppIconsServiceImpl implements IAppIconsService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async delete(iconId: string) {
    const { error } = await this.supabase
      .from('appIcon')
      .delete()
      .eq('id', iconId)
    if (error) throw error
  }

  async get(iconId: string): Promise<AppIcon | null> {
    const response = await this.supabase
      .from('appIcon')
      .select('*')
      .eq('id', iconId)
      .limit(1)
      .single()
    return response.data as AppIcon | null
  }

  async getAll(options?: QueryOptions): Promise<AppIcon[]> {
    const { data, error } = await this.supabase.from('appIcon').select('*')

    if (error) throw error

    return data as unknown as AppIcon[]
  }

  async update(iconId: string, data: any): Promise<AppIcon> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('appIcon')
      .update(updateDTO as never)
      .eq('id', iconId)
      .select('*')

    if (updateModelError) throw updateModelError

    return updateResponse[0]
  }

  async create(model: AppIconCreateDTO): Promise<AppIcon> {
    const fileUrl = await this.uploadAppIcon(model.name, model.file);

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('appIcon')
      .insert([{
        name: model.name,
        url: fileUrl
      }])
      .select()

    if (insertModelError) throw insertModelError

    return insertResponse[0]
  }

  async uploadAppIcon(filename: string, file: File): Promise<string> {
    const { data: fileResponse, error: fileError } = await uploadFileAsync(
        'app-icons',
        `${filename}.svg`,
        file,
    )

    if (fileError) throw fileError

    const image = getFileStorageUrl('app-icons', fileResponse.path)

    if(!image) {
      throw new Error("No image saved!");
    }

    return image;
  }
}
