import { useUserSession } from '~/stores/userSession';
import type { RouteLocationNormalized } from "vue-router";

export function canActivate(route: RouteLocationNormalized): boolean {
  if(!Boolean(route.meta?.allowedRoles)) return true;
  const userRole: string | undefined = useUserSession().role;
  if(!userRole) return false;

  return (route.meta.allowedRoles as Array<string>).includes(userRole);
}
