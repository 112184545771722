import type { QueryOptions } from "~/services/types";
import type { RiskLevel } from "~/types";
import type { PostgrestError } from "@supabase/postgrest-js";
import { SupabaseClient } from "@supabase/supabase-js";

export declare abstract class IRiskLevelsService {
  abstract getAll(options?: QueryOptions): Promise<RiskLevel[]>;

  abstract get(riskLevelId: string): Promise<RiskLevel | null>;

  abstract update(riskLevelId: string, data: any): Promise<RiskLevel>;

  delete(riskLevelId: string): Promise<void>;

  abstract create(model: any): Promise<RiskLevel>;
}

export class RiskLevelsServiceImpl implements IRiskLevelsService {
  private readonly supabase: SupabaseClient;

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient;
  }

  async delete(riskLevelId: string) {
    const { error } = await this.supabase
      .from("riskLevel")
      .delete()
      .eq("id", riskLevelId);
    if (error) throw error;
  }

  async get(riskLevelId: string): Promise<RiskLevel | null> {
    const response = await this.supabase
      .from("riskLevel")
      .select("*")
      .eq("id", riskLevelId)
      .limit(1)
      .single();
    return response.data as RiskLevel | null;
  }

  async getAll(options?: QueryOptions): Promise<RiskLevel[]> {
    const { data, error } = await this.supabase.from("riskLevel").select("*");

    if (error) throw error;

    return data as unknown as RiskLevel[];
  }

  async update(riskLevelId: string, data: any): Promise<RiskLevel> {
    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from("riskLevel")
      .update(updateDTO as never)
      .eq("id", riskLevelId)
      .select("*");

    if (updateModelError) throw updateModelError;

    return updateResponse[0];
  }

  async create(model: any): Promise<RiskLevel> {
    let updateDTO: any = {};
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key];
    });

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from("riskLevel")
      .insert([updateDTO as never])
      .select();

    if (insertModelError) throw insertModelError;

    return insertResponse[0];
  }
}
