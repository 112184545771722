import type {
  ReportEntry,
  ReportEntryCreateDTO,
  ReportEntryUpdateDTO,
  ReportGroup,
} from '~/types'
import { SupabaseClient } from '@supabase/supabase-js'
import { v4 as uuid } from 'uuid'
import unidecode from 'unidecode'
import type {LngLat} from "mapbox-gl";
import type {FeatureCollection} from "@turf/helpers";

export interface ChoroplethReportResponse extends FeatureCollection {
  events: any[];
}

export declare abstract class IReportsService {
  abstract getAll(queryOverride?: string): Promise<ReportEntry[]>
  abstract get(reportId: string, queryOverride?: string): Promise<ReportEntry>
  abstract update(
    reportId: string,
    data: ReportEntryUpdateDTO,
    queryOverride?: string,
  ): Promise<ReportEntry>
  abstract delete(reportId: string): Promise<void>
  abstract create(
    data: ReportEntryCreateDTO,
    queryOverride?: string,
  ): Promise<ReportEntry>

  // Choropleth
  abstract choroplethByEventCount(southWestBound: LngLat, northEastBound: LngLat, startDate: Date, endDate: Date): Promise<ChoroplethReportResponse>;
  abstract choroplethByRiskLevel(southWestBound: LngLat, northEastBound: LngLat, startDate: Date, endDate: Date): Promise<ChoroplethReportResponse>;
}

export class ReportsServiceImpl implements IReportsService {
  private readonly supabase: SupabaseClient
  private readonly bucket: string = 'reports'

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async getAll(queryOverride?: string): Promise<ReportEntry[]> {
    const { data, error } = await this.supabase
      .from('reportEntry')
      .select(queryOverride ? queryOverride : '*')

    if (error) throw error

    return data as unknown as ReportEntry[]
  }

  async get(reportId: string, queryOverride?: string): Promise<ReportEntry> {
    const { data, error } = await this.supabase
      .from('reportEntry')
      .select(queryOverride ? queryOverride : '*')
      .eq('id', reportId)
      .limit(1)
      .single()

    if (error) throw error

    return data as unknown as ReportEntry
  }

  async update(
    reportId: string,
    data: ReportEntryUpdateDTO,
    queryOverride?: string,
  ): Promise<ReportEntry> {
    let detailsUpdateDTO: any = {}
    let { file, ...model } = data.model

    let fileUrl: string | undefined
    if (file) {
      fileUrl = await this.uploadReportFile(file)
    }

    const details: any = {
      ...model,
      url: fileUrl,
    }

    Object.keys(details).forEach((key: string) => {
      if (details[key] !== undefined) detailsUpdateDTO[key] = details[key]
    })

    // Upload File first

    const { data: updateResponse, error: updateError } = await this.supabase
      .from('reportEntry')
      .update(detailsUpdateDTO as never)
      .eq('id', reportId)
      .select(queryOverride ? queryOverride : '*')

    if (updateError) throw updateError
    const reportRecord = updateResponse[0] as unknown as ReportEntry

    const reportGroups = await this.updateReportGroups(
      reportRecord.id!,
      data.groups ?? [],
    )

    return {
      ...reportRecord,
      reportGroups,
    } as ReportEntry
  }

  async delete(reportId: string): Promise<void> {
    const { error } = await this.supabase
      .from('reportEntry')
      .delete()
      .eq('id', reportId)
    if (error) throw error
  }

  async create(
    data: ReportEntryCreateDTO,
    queryOverride?: string,
  ): Promise<ReportEntry> {
    let detailsCreateDTO: any = {}
    let { file, ...model } = data.model

    let fileUrl: string | undefined
    if (file) {
      fileUrl = await this.uploadReportFile(file)
    }

    const details: any = {
      ...model,
      url: fileUrl,
    }

    Object.keys(details).forEach((key: string) => {
      if (details[key] !== undefined) detailsCreateDTO[key] = details[key]
    })

    const { data: createResponse, error: createError } = await this.supabase
      .from('reportEntry')
      .insert(detailsCreateDTO as never)
      .select(queryOverride ? queryOverride : '*')

    if (createError) throw createError
    const reportRecord = createResponse[0] as unknown as ReportEntry

    const reportGroups = await this.createReportGroups(
      reportRecord.id!,
      data.groups ?? [],
    )

    return {
      ...reportRecord,
      reportGroups,
    } as ReportEntry
  }

  private async createReportGroups(reportId: string, groupIds: string[]) {
    if (groupIds.length === 0) return []

    const items: ReportGroup[] = groupIds.map(
      (groupId: string) =>
        ({
          groupId: groupId,
          reportId: reportId,
        } as ReportGroup),
    )

    const { data: insertResponse, error: insertError } = await this.supabase
      .from('reportGroupLink')
      .insert(items as never)
      .select()

    if (insertError) throw insertError

    return insertResponse as ReportGroup[]
  }

  private async updateReportGroups(reportId: string, groupIds: string[]) {
    if (groupIds.length === 0) return []

    const { error } = await this.supabase
      .from('reportGroupLink')
      .delete()
      .eq('reportId', reportId)

    if (error) throw error

    return await this.createReportGroups(reportId, groupIds)
  }

  private async uploadReportFile(file: File) {
    const fileName = `${Date.now()}-${unidecode(file?.name ?? uuid())}`
    const { data: fileUploadResponse, error: fileError } =
      await uploadFileAsync(this.bucket, fileName, file)

    if (fileError) throw fileError

    return getFileStorageUrl(this.bucket, fileUploadResponse!.path)
  }

  public async choroplethByEventCount(southWestBound: LngLat, northEastBound: LngLat, startDate: Date, endDate: Date): Promise<ChoroplethReportResponse> {
    const { data: jsonb, error: requestError } = await this.supabase.rpc('choropleth_by_event_count', {
      bottom_left_longitude: southWestBound.lng,
      bottom_left_latitude: southWestBound.lat,
      top_right_longitude: northEastBound.lng,
      top_right_latitude: northEastBound.lat,
      event_start: startDate.toISOString(),
      event_end: endDate.toISOString()
    });

    if(requestError) throw requestError;

    return jsonb;
  }

  public async choroplethByRiskLevel(southWestBound: LngLat, northEastBound: LngLat, startDate: Date, endDate: Date): Promise<ChoroplethReportResponse> {
    const { data: jsonb, error: requestError } = await this.supabase.rpc('choropleth_by_risk_level', {
      bottom_left_longitude: southWestBound.lng,
      bottom_left_latitude: southWestBound.lat,
      top_right_longitude: northEastBound.lng,
      top_right_latitude: northEastBound.lat,
      event_start: startDate.toISOString(),
      event_end: endDate.toISOString()
    });

    if(requestError) throw requestError;

    return jsonb;
  }
}
